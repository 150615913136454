<template>
  <div>
    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="4" lg="4" xl="4">
          <v-toolbar-title class="ml-2 mt-2"
            >Relatório - Positivações</v-toolbar-title
          >
        </v-col>

        <v-col
          class="colunaHeader"
          cols="12"
          sm="12"
          md="8"
          lg="8"
          xl="8"
          align="end"
        >
          <v-btn class="ml-2 my-1" color="primary" @click="exportar">
            <v-icon left>mdi-microsoft-excel</v-icon> Exportar
          </v-btn>
          <v-btn
            class="ml-2 my-1"
            color="primary"
            :disabled="botaoExportarPPTDesativar"
            @click="exportarPPT()"
          >
            <v-icon left>mdi-microsoft-powerpoint</v-icon> Exportar PPT
          </v-btn>
        </v-col>
      </v-row>
      <v-card class="mt-2 py-4 px-2" elevation="1">
        <v-row no-gutters>
          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              v-model="filtro.dates"
              :items="allDates()"
              label="Mês/Ano"
              
              @change="filter()"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              v-model="filtro.campanha"
              :items="items"
              :search-input.sync="search"
              hide-no-data
              hide-details
              label="Campanha"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              class="text-capitalize"
              :items="['raia', 'drogasil']"
              label="Bandeira"
              v-model="filtro.bandeira"
              @change="filter()"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filtro.peca"
              :search-input.sync="search2"
              :items="items2"
              hide-no-data
              hide-details
              label="Peça"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-autocomplete
              outlined
              dense
              clearable
              prepend-inner-icon="mdi-magnify"
             
              :auto-select-first="true"
              v-model="filtro.filial"
              :items="items3"              
              item-text="label"
              item-value="descricao"          

              label="Filial"
            >

            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
            <v-select
              outlined
              dense
              multiple
              v-model="group"
              :items="groupItems"
              @blur="filter()"
              label="Agrupador"
            ></v-select>
            <br />
          </v-col>

          <v-spacer></v-spacer>

          <v-col cols="12" sm="6" md="5" lg="3" xl="3">
            <v-btn
              class="ml-2 mb-1"
              color="primary"
              @click="
                filtro = {};
                filter();
              "
            >
              <v-icon left>mdi-filter-off</v-icon> Limpar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>

      <v-card class="mt-2 py-4 px-2" elevation="1">
        <!-- ====================
            TABLE
            =================== -->
        <template v-if="dados.length > 0">
          <v-data-table
            :headers="headers"
            :items="dados"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.imagem`]="{ item }">
              <v-btn
                small
                color="primary"
                @click="
                  () => {
                    Download(
                      item.imagem,
                      `${item['Mês/Ano']} - ${item.campanha} - ${item.filial} - ${item.peca}`
                    );
                  }
                "
                block
                >Baixar</v-btn
              >
            </template>
          </v-data-table>
        </template>
        <template v-if="dados.length == 0">
          <div>Não foram encontrados nenhum resultado</div>
        </template>

        <!-- ====================
            TABLE
            =================== -->
      </v-card>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>

    
    </v-overlay>

    <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="progresso"
      >
    
        <template>
       <v-card>
            <v-toolbar
              color="primary"
              dark
            >Progresso</v-toolbar>
            <v-card-text>
                
      <v-progress-linear
        v-if="progresso"
        v-model="indexProgresso"
        color="amber"
        height="25"
        class="mt-5"
      >
        <template v-slot:default="{ value }">
          <strong>{{ Math.ceil(value) }}%</strong>
        </template>


      </v-progress-linear> <br>
            
              

          <v-slider
            v-if="progresso"
            v-model="indexProgressoArquivos"          
            thumb-label="always"
            class="align-center"
            :max="indexTotal"
            :min="0"
            hide-details
            
          >
          </v-slider>
    


            </v-card-text>

             <v-card-actions class="">
               <v-btn class="justify-start" color="error" @click="$router.go();" >CANCELAR</v-btn>
               <h2 align="end" justify="end"> 0{{index}} Arquivos a serem carregados</h2>
            </v-card-actions>
            
          </v-card>
        </template>
      </v-dialog>

    <v-snackbar v-model="snackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>

    <notifications group="notificacao" />
  </div>
</template>

<script>

import axios from "axios";
import XLSX from "xlsx";

export default {
  data: () => ({
    groupItems: ["data", "bandeira", "campanha", "filial", "peca"],
    group: ["data", "bandeira", "campanha", "filial", "peca"],
    dialog: false,
    snackbar: false,
    snackbarText: "",
    overlay: true,
    pecas: [],
    campanhas: [],
    lojas: [],
    filtro: {},
    teste: [],
    novoCadastro: {},
    itemsPerPageArray: [1, 5, 10, 15],
    search: null,
    search2: null,
    search3: null,
    sortDesc: false,
    page: 1,
    itemsPerPage: 10,
    sortBy: "nome",
    numberOfPages: 1,
    items: [],
    items2: [],
    items3: [],
    editando: {},
    dialogEditando: false,
    headers: [],
    dados: [],
    allData: [],
    progresso: false,
    indexProgresso: 0,
    indexProgressoArquivos: 0,
    indexTotal: 0,
    arquivoTexto: "",
    fotos: 0,
    index: 0,
    arquivo: 1,
    botaoExportarPPTDesativar: false,
  }),
  computed: {
    novoCadastroSelectedAll() {
      return (
        this.novoCadastro &&
        this.novoCadastro.id_loja &&
        Array.isArray(this.novoCadastro.id_loja) &&
        this.novoCadastro.id_loja.length == this.lojas.length
      );
    },
    novoCadastroSelectedSome() {
      return (
        this.novoCadastro &&
        this.novoCadastro.id_loja &&
        this.novoCadastro.id_loja.length > 0
      );
    },
    iconSelectAllNovoCadastro() {
      if (this.novoCadastroSelectedAll) return "mdi-close-box";
      if (this.novoCadastroSelectedSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    editandoSelectedAll() {
      return (
        this.editando &&
        this.editando.id_loja &&
        Array.isArray(this.editando.id_loja) &&
        this.editando.id_loja.length == this.lojas.length
      );
    },
    editandoSelectedSome() {
      return (
        this.editando &&
        this.editando.id_loja &&
        this.editando.id_loja.length > 0
      );
    },
    iconSelectAllEditando() {
      if (this.editandoSelectedAll) return "mdi-close-box";
      if (this.editandoSelectedSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
  
    exportarPPT() {
     var lista = [...this.dados];
     if(lista.length < 2000) {
      this.overlay = true;
      this.progresso = true;
      this.arquivo = 1

      this.botaoExportarPPTDesativar = true;

      let pptx = new this.$pptxgen();

      

   setTimeout(async () => {
          var self = this;
         
         for (let i = 1; i <= Math.ceil(lista.length/100); i++) {

                pptx[i] = {}    
                
                pptx[i].nome = `Positivacoes_arquivo_${i}.pptx`

                pptx[i].pptx = new this.$pptxgen()

                pptx[i].pptx.author = 'IPDV'
                pptx[i].pptx.company = 'Raia Drogasil'
                pptx[i].pptx.subject = 'Positivações'
                pptx[i].pptx.title = 'Positivações' 
            }

            const proximaFoto = (i) => {
                
                let e = lista[i]
            
                let img = new Image()
               
                
                img.addEventListener("load", async () => {

                  if(e){            
                    self.index = await Math.ceil(lista.length/100)
                    self.indexTotal =  await lista.length
                   
                   var indexProgressoArquivos = setTimeout(() => {                      
                        self.indexProgressoArquivos = i;
                      }, 1000);

                       
                    //slida comprimento e altura = 10 / 5,62519685                    
                    let slide = pptx[self.arquivo].pptx.addSlide()

                     slide.addText('', { 
                        x: 0.1, 
                        y: 0, 
                        w: 9.9,
                        h: 0.7,
                        fill: '#00774D',
                    })
               
                    slide.addImage({
                        path: require("../assets/imagens/logo_branco.png"),
                        x: 9, 
                        y: 0,
                        w: 0.7, 
                        h: 0.7,
                    }) 
                    
                    slide.addImage({
                        path: require("../assets/imagens/mpdvlogo.png"),
                        x: 0.0, 
                        y: 0,
                        w: 0.7, 
                        h: 0.7,
                    })

                     let rows = [
              [
                {
                  text: "ID JAVA",
                  options: { fill: "00774D", color: "ffffff", bold: true },
                },
                {
                  text: "FILIAL",
                  options: { fill: "00774D", color: "ffffff", bold: true },
                },
                {
                  text: "MêS/ANO",
                  options: { fill: "00774D", color: "ffffff", bold: true },
                },
                {
                  text: "CAMPANHA",
                  options: { fill: "00774D", color: "ffffff", bold: true },
                },
                {
                  text: "PEÇA",
                  options: { fill: "00774D", color: "ffffff", bold: true },
                },
              ],
            ];

            let linha = [
              {
                text: e["id_java"],
                options: { fill: i % 2 ? "7cbba5" : "bcd7cd" },
              },
              {
                text: e["filial"],
                options: { fill: i % 2 ? "7cbba5" : "bcd7cd" },
              },
              {
                text: e["Mês/Ano"],
                options: { fill: i % 2 ? "7cbba5" : "bcd7cd" },
              },
              {
                text: e["CampanhaAPP"],
                options: { fill: i % 2 ? "7cbba5" : "bcd7cd" },
              },
              { text: e["PecaAPP"], options: { fill: i % 2 ? "7cbba5" : "bcd7cd" } },
            ];

            rows.push(linha);
                    
                    slide.addTable(rows, { 
                        x: 4.9, 
                        y: 0.9,
                        w: 4.9,
                        h: 4.5,
                        rowH: 0.2,
                        colW: [0.8, 3.5, 0.6],
                        color: '#000000',                         
                        fontSize: 9,
                        fill: '#cccccc',
                        align: 'center',
                        border: {
                            pt: 1,
                            color: '#ffffff',
                            type: 'solid',
                        },
                        autoPage: true,
                        autoPageRepeatHeader: true,
                    })  

                    let altura = 4.5
                    let comprimento = 4.5

                    if(img.naturalWidth > img.naturalHeight) { //foto na horizontal
                        
                        altura = Math.round(100*comprimento*img.naturalHeight/img.naturalWidth)/100
                    }
                    else {  //foto na vertical

                        comprimento = Math.round(100*altura*img.naturalWidth/img.naturalHeight)/100
                    }

                    slide.addImage({
                        path: e.imagem, 
                        x: 0.2, 
                        y: 0.9,
                        w: comprimento, 
                        h: altura,
                    })

                  }
                  
                    if((i+1) == lista.length || (i+1) % 100 == 0 ) {
                     self.$notify({
                            group: 'notificacao',
                            classes:'success',
                            title: 'Atenção',
                            text: `Preparando o arquivo para ser baixado`,
                            duration: -3000,
                            closeOnClick:true
                           
                          });
                       clearTimeout(indexProgressoArquivos)
                       self.overlay = true;
                       self.progresso = true;                          
                       self.botaoExportarPPTDesativar = true;
                        

                     var refreshIntervalId = setInterval(() => {                      
                        self.indexProgresso += 1;
                      
                      if(self.indexProgresso < 99 ){
                            self.indexProgresso += 1;
                        }else {
                          self.indexProgresso = 99;
                        }

                      }, 2000);


                        pptx[self.arquivo].pptx.writeFile({fileName: pptx[self.arquivo].nome})
                        .then(() => {

                            

                            pptx[self.arquivo] = null

                            self.arquivo++

                            if((i+1) == lista.length){
                                    self.overlay = false;
                                    self.botaoExportarPPTDesativar = false;
                                    self.progresso = false;                                                  
                            
                            } 
                            else {
                                proximaFoto(i+1)
                            }  
                        })
                        .catch((err) => {

                            console.error(err)  

                           
                        }).finally(() => {
                             setTimeout(() => {
                                    var intervalo = setInterval(() => {
                                        self.indexProgresso = 100;
                                    }, 3000);
                                    clearInterval(intervalo);  
                                    clearInterval(refreshIntervalId);
                                  
                              }, 500);
                                  
                             });
                    } 
                    else {
                         self.indexProgresso = 0  
                        proximaFoto(i+1)                   
                    }                    
                })

                  img.addEventListener("error", () => {
                  
                    self.$notify({
                            group: 'notificacao',
                            title: 'Atenção',
                            text: `Não possivel carregar Imagem da Campanha: ${e["CampanhaAPP"]}`,
                            duration: -3000,
                            closeOnClick:true
                          });
                  
                    if((i+1) == lista.length || (i+1) % 100 == 0 ) {

                        pptx[self.arquivo].pptx.writeFile({fileName: pptx[self.arquivo].nome})
                        .then(() => {

                        

                            pptx[self.arquivo] = null

                            self.arquivo++

                            if((i+1) == lista.length){
                                                                                        
                                    self.overlay = false;
                                    self.botaoExportarPPTDesativar = false;
                                    self.progresso = false;
                            } 
                            else {

                                proximaFoto(i+1)
                            }  
                        })
                        .catch((err) => {
                            
                            console.error(err)  

                          
                        })
                    } 
                    else {
                    
                        proximaFoto(i+1)                   
                    } 
                })

                img.crossOrigin = 'anonymous'                              
                img.src= e.imagem
                
                

            }

            
            if(lista.length){

                proximaFoto(0)
            } 

            
        
         
        
      }, 500);
     }else{
        this.snackbar = true;
        this.snackbarText = `A quantidade de slides ultrapassa o limite permitido`;
     }      
 
    },
    novoCadastroSelectAll() {
      if (!this.novoCadastroSelectedAll) {
        return (this.novoCadastro.id_loja = this.lojas.map((val) => val.id));
      } else {
        return (this.novoCadastro.id_loja = []);
      }
    },
    editandoSelectAll() {
      if (!this.editandoSelectedAll) {
        this.editando.id_loja = this.lojas.map((val) => val.id);
      } else {
        this.editando.id_loja = [];
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    allDates() {
      const dates = new Set();
      this.campanhas.forEach((val) =>
        dates.add(`${val.mes.toString().padStart(2, "0")}/${val.ano}`)
      );
      return Array.from(dates);
    },
    stringToDate(data) {
      let date = new Date(data);
      return {
        dia: date.getUTCDate().toString().padStart(2, "0"),
        mes: (date.getUTCMonth() + 1).toString().padStart(2, "0"),
        ano: date.getUTCFullYear(),
        datepicker: `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getUTCDate().toString().padStart(2, "0")}`,
        string: `${date.getUTCDate().toString().padStart(2, "0")}/${(
          date.getUTCMonth() + 1
        )
          .toString()
          .padStart(2, "0")}/${date.getUTCFullYear()}`,
        date,
      };
    },
    readerData(rawFile, sheet) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const selectedSheet = workbook.SheetNames[sheet];
          const worksheet = workbook.Sheets[selectedSheet];
          // const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet);
          const meta = { sheetName: selectedSheet };
          resolve({ results, meta });
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    async xlstoidloja(xls) {
      const results = (await this.readerData(xls, 0)).results;

      if (results.length == 0) {
        this.snackbar = true;
        this.snackbarText = `não foram encontrados nenhum registro`;
        return [];
      }

      if (!(results[0].id_java != null) && !(results[0]["id java"] != null)) {
        this.snackbar = true;
        this.snackbarText = `È necessário ter o campo id java ou id_java`;
        return [];
      }
      const id_loja = results.map((val) => val.id_java || val["id java"]);
      return id_loja;
    },
    async novoCadastroXls(xls) {
      if (xls instanceof File) {
        const id_loja = await this.xlstoidloja(xls);
        const lojasEncontradas = id_loja.filter((val) =>
          this.lojas.find((loja) => loja.id == val)
        );
        const lojasNaoEncontradas = id_loja.filter(
          (val) => !this.lojas.find((loja) => loja.id == val)
        );
        if (lojasNaoEncontradas.length > 0) {
          this.snackbar = true;
          this.snackbarText = `não foram cadastradas as lojas com os seguintes id_java: ${lojasNaoEncontradas.join(
            ", "
          )}`;
        }

        const lojasEncontradasUnicas = new Set();
        lojasEncontradas.forEach((val) => lojasEncontradasUnicas.add(val));
        this.novoCadastro.id_loja = Array.from(lojasEncontradasUnicas);
      }
    },
    async editandoXls(xls) {
      if (xls instanceof File) {
        const id_loja = await this.xlstoidloja(xls);
        const lojasEncontradas = id_loja.filter((val) =>
          this.lojas.find((loja) => loja.id == val)
        );
        const lojasNaoEncontradas = id_loja.filter(
          (val) => !this.lojas.find((loja) => loja.id == val)
        );
        if (lojasNaoEncontradas.length > 0) {
          this.snackbar = true;
          this.snackbarText = `não foram cadastradas as lojas com os seguintes id_java: ${lojasNaoEncontradas.join(
            ", "
          )}`;
        }
        const lojasEncontradasUnicas = new Set();
        lojasEncontradas.forEach((val) => lojasEncontradasUnicas.add(val));
        this.editando.id_loja = Array.from(lojasEncontradasUnicas);
      }
    },
    nomePecas() {
      return this.pecas.map((val) => val.nome);
    },
    nomeLojas() {
      return this.lojas.map((val) => val.descricao);
    },
    pecaIDtoName(id) {
      return this.pecas.find((val) => val.id == id).nome;
    },
    lojaIDtoName(id) {
      return this.lojas.find((val) => val.id == id).descricao;
    },
    close() {
      this.dialog = false;
    },
    changeCurrentItens(value) {
      this.teste = value;
    },
    exportar() {
      const data = this.allData;
      const ws = XLSX.utils.json_to_sheet(data);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Positivações");
      XLSX.writeFile(wb, `positivacoes_${new Date().getTime()}.xlsx`);
    },
    baixarLojasXls() {
      const dadosLoja = this.lojas.map((val) => ({
        "id java": val.id_java,
        bandeira: val.bandeira,
        regiao: val.regiao,
        estado: val.estado,
        cidade: val.cidade,
        perfil: val.perfil,
        cluster: "POPULAR",
        tamanho: val.tamanho,
        "check out": val.check_out,
      }));
      const ws = XLSX.utils.json_to_sheet(dadosLoja);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Lista de Campanhas");
      XLSX.writeFile(wb, `lista_de_lojas_${new Date().getTime()}.xlsx`);
    },
    changePages(val) {
      this.numberOfPages = val;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    async filter() {
      const filter = {};

   
      this.campanhasFiltradas = this.campanhas;
      if (this.filtro.bandeira) filter.bandeira = this.filtro.bandeira;
      if (this.filtro.campanha) filter.campanha = this.filtro.campanha;
      if (this.filtro.peca) filter.peca = this.filtro.peca;
      if (this.filtro.filial) filter.filial = this.filtro.filial;
      if (this.filtro.dates)
        filter.mes = parseInt(this.filtro.dates.slice(0, 2), 10);
      if (this.filtro.dates)
        filter.ano = parseInt(this.filtro.dates.slice(3, 7), 10);

      const group = this.group.reduce((acc, cur) => {
        acc[cur] = true;
        return acc;
      }, {});

      this.allData = (
        await axios.post(
          `${this.$SERVER_URL}adm/positivado`,
          { filter, group },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        )
      ).data;

      if (this.allData.length > 0) {
        this.headers = Object.keys(this.allData[0])
         .filter(function(val) {
     
          if (val== "CampanhaAPP" ||val== "PecaAPP" ) {
            
            return false; // skip
          }  return true;})
          .map((val) => (
         
          
         {        
          text: val.toUpperCase(),
          align: "center",
          value: val,
          }
          ));
      }
      this.dados = this.allData;
     

      // if (this.filtro.dates && this.filtro.dates.length > 0) {
      //   this.campanhasFiltradas = this.campanhasFiltradas.filter(val =>
      //     this.filtro.dates.includes(
      //       `${val.mes.toString().padStart(2, "0")}/${val.ano}`
      //     )
      //   );
      // }

      this.dialogEditando = true;
      this.dialogEditando = false;
    },
    querySelections(v) {
      this.items = [
        ...this.campanhas
          .map((val) => val.nome)
          .filter((e) => {
            return (
              (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
            );
          }),
        v,
      ];
    },
    querySelections2(v) {
      this.items2 = [
        ...this.pecas
          .map((val) => val.nome)
          .filter((e) => {
            return (
              (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
            );
          }),
        v,
      ];
    },
    querySelections3(v) {
      this.items3 = [
        ...this.lojas
          .sort((a, b) => (a.descricao > b.descricao ? 1 : -1))
          .map((val) => ({
           label: val.id_java + '-'+val.descricao,
           descricao : val.descricao
          }))
         .filter((e) => {
            return (
              (e.descricao || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1
            );
          }),        
        v,
      ];


 
    },
    novoCadastroAllowedDates(val) {
      if (this.novoCadastro.data_ini) return val > this.novoCadastro.data_ini;
      return true;
    },
    editandoAllowedDates(val) {
      if (this.editando.data_ini_a) return val > this.editando.data_ini_a;
      return true;
    },
    async CriarNovo(novoCadastro) {
      //const regexAll = /[^\\]*\.(\w+)$/;
      if (
        novoCadastro.bandeira &&
        novoCadastro.data_fim &&
        novoCadastro.data_ini &&
        novoCadastro.nome &&
        novoCadastro.observacao
      ) {
        this.dialog = false;
        this.overlay = true;
        if (this.novoCadastro.pdf) {
          const link = (
            await axios.post(
              `${this.$SERVER_URL}adm/sync/pdf`,
              {},
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods":
                    "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                  "Access-Control-Allow-Headers":
                    "Origin, Content-Type, X-Auth-Token",
                },
              }
            )
          ).data;
          const pdf = this.novoCadastro.pdf;
          await axios.put(link.uploadUrl, pdf, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
              "Content-Type": pdf.type,
            },
          });
          this.novoCadastro.pdf = link.downloadUrl;
        } else {
          this.novoCadastro.pdf = "";
        }
        this.campanhas = (
          await axios.post(`${this.$SERVER_URL}adm/campanha`, novoCadastro, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          })
        ).data;
        this.campanhas = this.campanhas.map((val) => ({
          ...val,
          data_ini: this.stringToDate(val.data_ini),
          data_fim: this.stringToDate(val.data_fim),
          peca: val.id_peca.map((val) => this.pecaIDtoName(val)),
          loja: val.id_loja.map((val) => this.lojaIDtoName(val)),
        }));
        this.filter();
        this.overlay = false;
      } else {
        this.snackbar = true;
        this.snackbarText = "É necessáro preencher todos os campos";
      }
    },
    async Editar(editando) {
      this.overlay = true;
      this.dialogEditando = false;
      if (editando.pdf instanceof File) {
        const link = (
          await axios.post(
            `${this.$SERVER_URL}adm/sync/pdf`,
            {},
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods":
                  "GET, POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Headers":
                  "Origin, Content-Type, X-Auth-Token",
              },
            }
          )
        ).data;
        const pdf = editando.pdf;
        await axios.put(link.uploadUrl, pdf, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
            "Content-Type": pdf.type,
          },
        });
        editando.pdf = link.downloadUrl;
      }
      const {
        bandeira,
        data_fim_a,
        data_ini_a,
        id,
        id_loja,
        id_peca,
        observacao,
        pdf,
      } = editando;
      this.campanhas = (
        await axios.put(
          `${this.$SERVER_URL}adm/campanha/${id}`,
          {
            bandeira,
            data_fim: data_fim_a,
            data_ini: data_ini_a,
            id_loja,
            id_peca,
            observacao,
            pdf,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, POST, PATCH, PUT, DELETE, OPTIONS",
              "Access-Control-Allow-Headers":
                "Origin, Content-Type, X-Auth-Token",
            },
          }
        )
      ).data;
      this.campanhas = this.campanhas.map((val) => ({
        ...val,
        data_ini: this.stringToDate(val.data_ini),
        data_fim: this.stringToDate(val.data_fim),
        peca: val.id_peca.map((val) => this.pecaIDtoName(val)),
        loja: val.id_loja.map((val) => this.lojaIDtoName(val)),
      }));
      this.filter();
      this.overlay = false;
    },
    async Delete(id) {
      this.overlay = true;
      this.campanhas = (
        await axios.delete(`${this.$SERVER_URL}adm/campanha/${id}`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
        })
      ).data;
      this.campanhas = this.campanhas.map((val) => ({
        ...val,
        data_ini: this.stringToDate(val.data_ini),
        data_fim: this.stringToDate(val.data_fim),
        peca: val.id_peca.map((val) => this.pecaIDtoName(val)),
        loja: val.id_loja.map((val) => this.lojaIDtoName(val)),
      }));
      this.filter();
      this.overlay = false;
    },
    novoCadastroDataVerify() {
      if (
        this.novoCadastro.data_ini &&
        this.novoCadastro.data_fim &&
        this.novoCadastro.data_ini > this.novoCadastro.data_fim
      )
        this.novoCadastro.data_fim = null;
    },
    editandoDataVerify() {
      if (
        this.editando.data_ini_a &&
        this.editando.data_fim_a &&
        this.editando.data_ini_a > this.editando.data_fim_a
      )
        this.editando.data_fim = null;
    },
    async Download(url, label = "aaaaa") {
      var a = (
        await axios.get(url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
          responseType: "blob",
        })
      ).data;

      // const blob = new Blob([a], { type: "application/pdf" });
      const blob = new Blob([a]);
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      const split = url.split(".");
      const ext = split[split.length - 1];
      link.download = label + "." + ext;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    async downloadItem(url, label) {
      var a = (
        await axios.get(url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, POST, PATCH, PUT, DELETE, OPTIONS",
            "Access-Control-Allow-Headers":
              "Origin, Content-Type, X-Auth-Token",
          },
          responseType: "blob",
        })
      ).data;

      const blob = new Blob([a], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
  watch: {
    search(val) {
      val && val !== this.filtro.campanha && this.querySelections(val);
      this.filtro.campanha = val;
      this.filter();
    },
    search2(val) {
      val && val !== this.filtro.peca && this.querySelections2(val);
      this.filtro.peca = val;
      this.filter();
    },
    'filtro.filial'(val) {
      
      val && val !== this.filtro.filial && this.querySelections3(val);
     // this.filtro.filial = val;       
      this.filter();
    },
  },
  async mounted() {
    this.lojas = (
      await axios.get(`${this.$SERVER_URL}adm/loja`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
    ).data;
    this.lojas = this.lojas.map((val) => ({
      ...val,
      label: val.id_java + " - " + val.descricao,
    }));
    this.pecas = (
      await axios.get(`${this.$SERVER_URL}adm/allPecas`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
    ).data;
    this.campanhas = (
      await axios.get(`${this.$SERVER_URL}adm/campanha`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
        },
      })
    ).data;
    this.campanhas = this.campanhas.map((val) => ({
      ...val,
      data_ini: this.stringToDate(val.data_ini),
      data_fim: this.stringToDate(val.data_fim),
      peca: val.id_peca.map((val) => this.pecaIDtoName(val)),
      loja: val.id_loja.map((val) => this.lojaIDtoName(val)),
    }));
    this.campanhasFiltradas = this.campanhas;

    // this.allData = (
    //   await axios.get(`${this.$SERVER_URL}adm/positivacao`, {
    //     headers: {
    //       "Access-Control-Allow-Origin": "*",
    //       "Access-Control-Allow-Methods":
    //         "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    //       "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
    //     }
    //   })
    // ).data;

    await this.filter();

    this.$store.commit("importacaoPecas/pecasJaCadastradas", {
      pecas: this.pecas,
    });

    this.querySelections("");
    this.querySelections2("");
    this.querySelections3("");
    this.overlay = false;
  },
};
</script>
<style>
</style>